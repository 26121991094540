import React from 'react';

export function Tags({ labels, backgroundColor }) {
  let className = `tag ${backgroundColor}`;
  return (
    <div>
      {labels.map((label, index) => (
        <div className={className} key={index}>{label}</div>
      ))}
    </div>
  );
}

const TagsBackgroundColor = {
  RED: "red",
  BLUE: "blue",
  GREEN: "green",
  PURPLE: "purple"
}

Tags.BackgroundColor = TagsBackgroundColor

export const Colors = {
  GREY: "#2e3842",
  LIGHTBLUE: "#3375a0"
}
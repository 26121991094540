import React from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { Colors } from './Text';

class Nav extends React.Component {
  static propTypes = {
    menuOpen: PropTypes.func,
    menuClose: PropTypes.func,
    links: PropTypes.object,
  };

  handleClickOutside = (evt) => {
    const { menuClose } = this.props;
    menuClose();
  };

  render() {
    const { menuOpen, menuClose, links } = this.props;
    const linksJsx = Object.entries(links).map(([key, value], index) => {
      return (
        <li key={index}>
          <AniLink paintDrip hex={Colors.GREY} to={value}>
            {key}
          </AniLink>
          {/*<Link to={value}>{key}</Link>*/}
        </li>
      );
    });

    return (
      <nav id="nav">
        <ul>
          <li className="special">
            <a
              href="#menu"
              onClick={(e) => {
                e.preventDefault();
                menuOpen();
              }}
              className="menuToggle"
            >
              <span>Menu</span>
            </a>
            <div id="menu">
              <ul>{linksJsx}</ul>
              <a
                className="close"
                onClick={(e) => {
                  e.preventDefault();
                  menuClose();
                }}
                href="#menu"
              >
                {''}
              </a>
            </div>
          </li>
        </ul>
      </nav>
    );
  }
}

export default onClickOutside(Nav);

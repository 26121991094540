import React, { useState } from 'react';
import Nav from './Nav';
import SearchBar from './SearchBar'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Colors } from "./Text";

export default function SideBar({ fullMenu, links, enableSearch, handleSearchInputChange }) {
  const [headerOpen, setHeader] = useState(false);

  var sideBar;
  var searchBar;
//  if (links) {
//    sideBar = (
//      <div className={headerOpen ? 'is-menu-visible' : ''}>
//        <Nav menuOpen={() => setHeader(true)}
//             menuClose={() => setHeader(false)}
//             links={links}
//        />
//      </div>
//    )
//  }
  if (enableSearch) {
    searchBar = (
      <SearchBar handleSearchInputChange={handleSearchInputChange} />
    )
  }

  return (
    <header id="header" className={`${fullMenu ? '' : 'alt'}`}>
      <h1>
        <AniLink paintDrip hex={Colors.GREY} to={Object.values(links)[0]}>
          <span id="favicon" /> {Object.keys(links)[0]}
        </AniLink>
        {/*<Link to="/">Kunal Kapoor</Link>*/}
      </h1>
      {sideBar}
      {searchBar}
    </header>
  );
}

module.exports = {
    siteTitle: "Kunal Kapoor",
    siteDescription: "Kunal Kapoor's Personal Portfolio and Blog",
    author: "Kunal Kapoor",
    manifestName: "Kapoor Recipes",
    manifestShortName: "Kapoor Recipes", // max 12 characters
    manifestStartUrl: "/recipes/",
    manifestBackgroundColor: "#663399",
    manifestThemeColor: "#663399",
    manifestDisplay: "standalone",
    manifestIcon: "static/assets/logo.png",
    pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
    heading: "Kunal Kapoor",
    subHeading: "Software Developer | Aspiring Architect | Innovator | Guitarist | Gamer",
    // social
    socialLinks: [
        {
            style: "brands",
            icon: "fa-github",
            name: "Github",
            url: "https://github.com/kunalkapoor",
        },
        {
            style: "brands",
            icon: "fa-linkedin",
            name: "Twitter",
            url: "https://www.linkedin.com/in/kunalkapoor2",
        },
        {
            style: "brands",
            icon: "fa-stack-overflow",
            name: "StackOverflow",
            url: "https://stackoverflow.com/users/698626/kunal-kapoor",
        },
        // {
        //   style: "solid",
        //   icon: "fa-envelope",
        //   name: "Email",
        //   url: "mailto:test@example.com",
        // },
    ],
};

import React from 'react';
import PropTypes from 'prop-types';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

class SearchBar extends React.Component {
  static propTypes = {
      handleSearchInputChange: PropTypes.func,
    };

  render() {
    const { handleSearchInputChange } = this.props;
    return (
      <div id="searchbar">
        <input type="text" aria-label="Search" placeholder="Search" onChange={handleSearchInputChange} />
      </div>
    );
  }
}

export default SearchBar;

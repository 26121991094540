import React from 'react';
import config from '../../config';

export default function Footer() {
  return (
    <footer id="footer">
      <ul className="icons">
        {config.socialLinks.map((social) => {
          const { style, icon, name, url } = social;
          return (
            <li key={url}>
              <a href={url} className={`icon ${style} ${icon} bigger`}>
                <span className="label">{name}</span>
              </a>
            </li>
          );
        })}
      </ul>
      <ul className="copyright">
        <li>
          <span className="icon fa-copyright" /> Kunal Kapoor
        </li>
      </ul>
      <ul className="copyright small">
        <li>
          Site built using <a href="https://www.gatsbyjs.org/">Gatsby</a>
        </li>
        <li>
          Using Gatsby starter:{' '}
          <a href="https://github.com/anubhavsrivastava/gatsby-starter-spectral">Gatsby-Starter-Spectral</a>
        </li>
        <li>
          Design from <a href="http://html5up.net">HTML5 UP</a>
        </li>
        <li>
          <a href="https://www.freepik.com/vectors/technology">Technology vector created by stories - www.freepik.com</a>
        </li>
        <li>
          <a href="https://www.freepik.com/vectors/logo">Logo vector created by maestro99 - www.freepik.com</a>
        </li>
      </ul>
    </footer>
  );
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';

import '../assets/sass/main.scss';
import Footer from './Footer';
import SideBar from './Sidebar';
import Img from 'gatsby-image/index';
import BackgroundImage from 'gatsby-background-image'

class Layout extends Component {
  static propTypes = {
    fullMenu: PropTypes.bool,
    menuLinks: PropTypes.object,
    enableSearch: PropTypes.bool,
    handleSearchInputChange: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      isPreloaded: true,
    };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { children, fullMenu, menuLinks, enableSearch, handleSearchInputChange } = this.props;
    const { isPreloaded } = this.state;

    const links = menuLinks
      ? menuLinks
      : {
          Home: '/',
          'Tech Blogs': '/blogs'
        };

    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
            background: file(relativePath: { eq: "scenic-view-of-mountain-during-evening-2085998-dark.jpg" }) {
              relativePath
              childImageSharp {
                fluid(maxWidth: 6000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={(data) => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: 'Portfolio' },
                { name: 'keywords', content: 'site, web' },
              ]}
            >
              <html lang="en" />
            </Helmet>
            <div className={isPreloaded ? 'landing main-body is-preload' : 'landing main-body'}>
              <BackgroundImage
                Tag="section"
                className="gatsby-background-image"
                fluid={data.background.childImageSharp.fluid}
                backgroundColor="#2e3842"
              >
                <div id="page-wrapper">
                  {/*<Img fluid={data.background.childImageSharp.fluid} className="gatsby-background-image" />*/}
                  <SideBar fullMenu={fullMenu} links={links} enableSearch={enableSearch} handleSearchInputChange={handleSearchInputChange} />
                  {children}
                  <Footer />
                </div>
              </BackgroundImage>
            </div>
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
